import React, { useEffect, useState } from "react";
import { Linkedin, Mail, Youtube, Twitter, ChevronDown } from 'lucide-react';

function App() {
  const [darkMode, setDarkMode] = useState(true); // Default to dark mode
  const [showSections, setShowSections] = useState({
    writing: false,
    projects: false,
    currentlyBuilding: false,
    reading: false,
  });

  useEffect(() => {
    document.documentElement.classList.add("dark"); // Apply dark class on mount
  }, []);

  const toggleSection = (section) => {
    setShowSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className={`min-h-screen ${darkMode ? "bg-gray-900 text-white" : "bg-white text-gray-900"} font-serif p-8`}>
      <header className="flex items-center mb-8">
        <img 
          src="/images/headshot.jpg" 
          alt="Profile" 
          className="w-48 h-48 rounded-full mr-4 object-cover" 
        />
        <div>
        <h1 className="text-4xl font-bold">Vedant Nair</h1>
          <div className="flex space-x-4">
            {/* Use the imported icons as JSX components */}
            <a href="https://www.linkedin.com/in/vedantnair/" style={{ color: 'white' }} aria-label="LinkedIn">
              <Linkedin className="inline-block" />
            </a>
            <a href="mailto:vedant.nair@gmail.com" style={{ color: 'white' }} aria-label="Email">
              <Mail className="inline-block" />
            </a>
            <a href="https://www.youtube.com/@isladelvante" style={{ color: 'white' }} aria-label="YouTube">
              <Youtube className="inline-block" />
            </a>
            <a href="https://x.com/vedantnair__" style={{ color: 'white' }} aria-label="Twitter">
              <Twitter className="inline-block" />
            </a>
          </div>
        </div>
      </header>
      <section className="mb-8">
      <p className="text-lg font-bold">
  I'm a founder, growth hacker, and competitor based in San Francisco, USA. 
</p>
      </section>

      <section className="mt-8">
        <h2 className="text-2xl font-semibold mb-2 cursor-pointer flex items-center" onClick={() => toggleSection('currentlyBuilding')}>
          <ChevronDown className={`mr-2 transition-transform ${showSections.currentlyBuilding ? 'rotate-180' : ''}`} />
          Currently Building
        </h2>
        {showSections.currentlyBuilding && (
          <p className="text-lg">
            I'm currently building <a href="https://miruml.com" className="underline">Miru</a>, software deployment infrastructure for robotics.<br /><br />
            After decades of the smartest people in the world trying to crack robotics, we're still in its infancy. Some of the toughest robotics research problems have been solved (though many more still remain), but the real problem lies with how to productionize robotics.<br /><br />
            Robotics teams are stick building bespoke software tooling to do tasks that are already solved in SaaS. As a result, robotics teams move slower, spend more money, and ship less more robots than they should. This is one of the reasons why historically, many of them have failed.<br /><br />
            We're building Miru because roboticists deserve world-class software. At Miru, we spend every waking hour thinking about how we can make software that is useful, reliable, and a delight to use.<br /><br />
            We're starting with OTA software updates, but our ambitions are much, much larger than that. Reach out if you're curious.
          </p>
        )}
      </section>

      <section className="mt-8">
        <h2 className="text-2xl font-semibold mb-2 cursor-pointer flex items-center" onClick={() => toggleSection('writing')}>
          <ChevronDown className={`mr-2 transition-transform ${showSections.writing ? 'rotate-180' : ''}`} />
          Writing
        </h2>
        {showSections.writing && (
          <ul className="list-disc pl-5">
            <li><a href = "https://vedantn.substack.com/p/on-college-and-dogma?r=44xhe2" className="underline">on college (and dogma)</a></li>
            <li><a href = "https://vedantn.substack.com/p/on-writing?r=44xhe2" className="underline">on writing</a></li>
            <li><a href = "https://vedantn.substack.com/p/henry-ford-and-the-future-that-awaits?r=44xhe2" className="underline">henry ford and the future that awaits</a></li>
            <li><a href = "https://vedantn.substack.com/p/the-lump-of-labor-fallacy" className="underline">the 'lump of labor' fallacy</a></li>
          </ul>
        )}
      </section>

      <section className="mt-8">
        <h2 className="text-2xl font-semibold mb-2 cursor-pointer flex items-center" onClick={() => toggleSection('projects')}>
          <ChevronDown className={`mr-2 transition-transform ${showSections.projects ? 'rotate-180' : ''}`} />
          Passionate About
        </h2>
        {showSections.projects && (
          <ul className="list-disc pl-5">
            <li>US Foreign Policy. I'm a student of <a href = "https://www.jeffsachs.org/" className = "underline"> Jeffery Sachs</a> and <a href = "https://www.mearsheimer.com/" className = "underline"> John Mearsheimer</a></li>
            <li>Automating physiscal labor. Billions of people do work that they hate in order to survive. My goal is that robotics will automate these jobs and proide increased quality of life to everyone through creative destruction.</li>
            <li>Physical excellence. I enjoy playing (and hate losing) sports. I also like to jump high and run fast.</li>
            <li>Logistics and Urban Planning -- and more broadly, the study of how humans and goods are transported and stored efficiently. Businesses like Uber, Flexport, and Amazon astonish me.</li>
            <li>Science fiction: Ender's Game, Three Body, Asimov, Andy Weir, etc. Please send me more recs! </li>
            <li>Longevity. I'm terrified of death. </li>
            <li>Walks. I hate 'grabbing a coffee'. I'd much rather go for a walk. In this way, SF, has been very kind to me. I enjoy the parks, beaches, and hikes it has to offer.</li>

          </ul>
        )}
      </section>

      {/* <section className="mt-8">
        <h2 className="text-2xl font-semibold mb-2 cursor-pointer flex items-center" onClick={() => toggleSection('reading')}>
          <ChevronDown className={`mr-2 transition-transform ${showSections.reading ? 'rotate-180' : ''}`} />
          Reading
        </h2>
        {showSections.reading && (
          <ul className="list-disc pl-5">
            <li>Elon Musk and Steve Jobs by Walter Isaacson</li>
            <li>Churchill by Paul Johnson</li>
            <li>The Founders (PayPal) by Jimmy Soni</li>
            <li>Look at Kindle and Apple Books</li>
          </ul>
        )}
      </section> */}
    </div>
  );
}

export default App;
